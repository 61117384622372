:root {
  --primary: rgb(0, 0, 0);
  --secondary: #3e270f;
  --nav-bg-1: #ffecce;
  --nav-bg-2: #ffebcb;
  --bg: #fff3ef;
  /*#bfe2e9; /*#cbeae9; /*#fafac6; /*#f7ffe8; */
  --shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.4);
}

/* This seems to change the background color of all pages */
body {
  background-color: var(--bg);
}

.logo {
  position: absolute;
  top: 1em;
  left: 2em;
  width: 4em;
}

/* #barChart {
  margin: 0.5em 3em;
} */

.App {
  text-align: center;
  margin: 4em;
}

.card {
  position: relative;
  text-align: center;
  background-color: rgba(240, 248, 255, 0);
  border: none;
}

.card-title {
  padding: 1em;
  font-size: 2em;
}

.card-text {
  padding: 0.5em 6em;
}

.chart-container {
  background-color: var(--bg) !important;
  border: none !important;
  margin-top: 2.5em;
}

.discordLink {
  text-decoration: none;
  color: black;
}

@media only screen and (min-width: 1024px) {
  .App {
    margin: 8em;
  }
}

@media only screen and (max-width: 768px) {
  .App {
    margin: 3em;
  }
}

@media only screen and (max-width: 535px) {
  .card-text {
    padding: 0.25em 1em;
  }

  .App {
    margin: 1em;
  }

  .logo {
    width: 3em;
  }
}

@media only screen and (max-width: 425px) {
  .card-title {
    padding: 1em;
    font-size: 1.5em;
  }

  .logo {
    top: 0.7em;
    left: 1.2em;
    width: 2em;
  }

  .card-text {
    padding: 0;
  }

  .chart-container {
    font-size: 14px;
  }
}